/* We are using only grid system from the bootstrap */
@import "bootstrap/dist/css/bootstrap-grid.min.css";
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #b9b9b9;
}

::-webkit-scrollbar-track-piece {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #b9b9b9;
  border-radius: 36px;
}

::-webkit-scrollbar-corner {
  background-color: #b9b9b9;
}

::-webkit-resizer {
  background-color: #4a4a4a;
}

/* width */
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

/* height */
.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

/* margin */
.no-margin {
  margin: 0 !important;
}

/* padding */
.no-padding {
  padding: 0 !important;
}

/* mat-table */
.mat-table {
  overflow: auto;
  max-height: 500px;
}

.mat-table-header {
  padding: 0 1.34375em;
}
.mat-table-header .mat-form-field {
  padding: 1.34375em 1.34375em 0 0;
}

/* mat dialog */
.mat-dialog-title {
  padding: 0 !important;
  margin: 10px 10px !important;
}

.mat-dialog-content {
  padding-bottom: 0px !important;
}

.mat-dialog-actions {
  padding: 0px 25px 20px !important;
}

/* colors */
.warn {
  color: #f44336;
}

.mat-mdc-snack-bar-container.toast-success {
  --mdc-snackbar-container-color: green;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
}

.mat-mdc-snack-bar-container.toast-error {
  --mdc-snackbar-container-color: red;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
}

.fw-bold {
  font-weight: 600;
}